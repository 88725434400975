$o-blue-highlight: #d8f4fc;
$o-blue-light: #20caf7;
$o-blue-dark: #3982a4;
$o-blue: #0C86B3;

$o-orange: #e59536;
$o-booked-at-school: #FF9421;

$o-green: #488f80;

$o-red: #ee4b4d;
$o-error: #e22929;

$o-gray-light: #eeeff0;
$o-border-color: #cacaca;
$o-box-bg-primary: #fbfbfb;
$o-box-bg-secondary: #f5f5f5;
$o-gray: #c4c4c4;

$o-gray-dark: #4d4d4c;
$o-black: #212529;
$o-gray-middle: #272727;

$o-border-radius: 1rem;
$o-border-radius-small: 7px;
$o-footer-height: 60px;
$o-reveal-menu-width: 100%;
$o-input-height: 44px;
$o-input-padding: 1.5rem;

$o-course-b-icon: url('/assets/img/course-b.png');
$o-course-g-icon: url('/assets/img/course-g.png');