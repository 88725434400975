@mixin mediaq($class) {
  @if $class == sm {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $class == xl {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

