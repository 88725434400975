@import "./_variables.scss";

$unavailable: #e22929;
$available: $o-green;
$booked-at-school: $o-booked-at-school;
$not-set: #cacaca;
$selected: $o-blue-light;

.calendar-days.availability,
.talent__calendar-days,
.timesheet-group .rebook {
  position: relative;

  &.with-controls {
    padding: 0 15px;
  }

  &.large {
    .custom-checkbox {
      .custom-control-label {
        width: 40px!important;
        height: 40px!important;
        line-height: 16px!important;
      }
    }
  }

  &.extra-large {
    .custom-checkbox {
      .custom-control-label {
        width: 50px!important;
        height: 50px!important;
        line-height: 20px!important;
      }
    }
  }

  &.mobile {
    display: flex;
    margin: 0 -15px;
    padding: 0 15px;

    &:before,
    &:after {
      content: "";
      width: 15px;
      height: 100%;
      position: absolute;
    }

    &:before {
      left: 0;
      z-index: 3;
      background: linear-gradient(to right, white 10%, rgba(255, 255, 255, 0.3) 100%);
    }

    &:after {
      right: 0;
      z-index: 3;
      background: linear-gradient(to left, white 10%, rgba(255, 255, 255, 0.3) 100%);
    }
  }

  .custom-checkbox {
    display: inline-block;
    padding: 0;
    border-radius: 100%;
    outline: none;
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0;
    }

    &.single {
      .custom-control-label {
        line-height: calc(37px - 16px);
      }
    }

    .custom-control-label {
      min-width: 37px;
      height: 37px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      padding: 8px 0;
      text-align: center;
      border: 0;
      background-color: $not-set;
      border-radius: 50%;
      cursor: default!important;
      color: #fff;
      font-family: "Ginger", Arial, Helvetica, sans-serif;
      user-select: none;

      .wd {
        font-size: 10px;
        line-height: 12px;
      }

      &::before,
      &::after {
        display: none;
      }

      &.past,
      &.faded {
        opacity: 0.6;
      }

      &.solid {
        opacity: 1 !important;
      }

      &.full {
        background: $available;
      }

      &.am {
        background: linear-gradient(to left, $not-set 50%, $available 50%);
      }

      &.pm {
        background: linear-gradient(to right, $not-set 50%, $available 50%);
      }

      &.all-day {
        background: $unavailable;
      }

      &.all-day-current-client {
        background: $booked-at-school;
      }

      &.booked-am {
        background: linear-gradient(to left, $not-set 50%, $unavailable 50%);
      }

      &.booked-pm {
        background: linear-gradient(to right, $not-set 50%, $unavailable 50%);
      }

      &.am_booked-pm {
        cursor: pointer;
        background: linear-gradient(to left, $unavailable 50%, $available 50%);
      }

      &.pm_booked-am {
        background: linear-gradient(to right, $unavailable 50%, $available 50%);
      }

      &.unavailable {
        background: $unavailable;
      }

      &.booked-am-current-client {
        background: linear-gradient(to left, $not-set 50%, $booked-at-school 50%);
      }

      &.booked-pm-current-client {
        background: linear-gradient(to right, $not-set 50%, $booked-at-school 50%);
      }

      &.am_booked-pm-current-client {
        background: linear-gradient(to left, $booked-at-school 50%, $available 50%);
      }

      &.pm_booked-am-current-client {
        background: linear-gradient(to right, $booked-at-school 50%, $available 50%);
      }

      &.booked-am-current-client_booked-pm {
        background: linear-gradient(to left, $unavailable 50%, $booked-at-school 50%);
      }

      &.booked-am_booked-pm-current-client {
        background: linear-gradient(to left, $booked-at-school 50%, $unavailable 50%);
      }

      &.am,
      &.pm,
      &.full,
      &.am_booked-pm,
      &.pm_booked-am,
      &.am_booked-pm-current-client,
      &.pm_booked-am-current-client {
        cursor: pointer;
        pointer-events: initial;
      }

      &.selected {
        &.full {
          background-color: $selected;
        }

        &.am {
          background: linear-gradient(to left, $not-set 50%, $selected 50%);
        }

        &.pm {
          background: linear-gradient(to right, $not-set 50%, $selected 50%);
        }

        &.am_booked-pm {
          background: linear-gradient(to left, $unavailable 50%, $selected 50%);
        }

        &.pm_booked-am {
          background: linear-gradient(to right, $unavailable 50%, $selected 50%);
        }

        &.am_booked-pm-current-client {
          background: linear-gradient(to left, $booked-at-school 50%, $selected 50%);
        }

        &.pm_booked-am-current-client {
          background: linear-gradient(to right, $booked-at-school 50%, $selected 50%);
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (min-width: 577px) {
  .calendar-days.availability {
    .custom-checkbox {
      .custom-control-label {
        &.full {
          &:hover {
            background: $selected;
          }
        }

        &.am {
          &:hover {
            background: linear-gradient(to left, $not-set 50%, $selected 50%);
          }
        }

        &.pm {
          &:hover {
            background: linear-gradient(to right, $not-set 50%, $selected 50%);
          }
        }

        &.am_booked-pm {
          &:hover {
            background: linear-gradient(to left, $unavailable 50%, $selected 50%);
          }
        }

        &.pm_booked-am {
          &:hover {
            background: linear-gradient(to right, $unavailable 50%, $selected 50%);
          }
        }
        &.am_booked-pm-current-client {
          &:hover {
            cursor: pointer;
            background: linear-gradient(to left, $booked-at-school 50%, $selected 50%);
          }
        }

        &.pm_booked-am-current-client {
          &:hover {
            cursor: pointer;
            background: linear-gradient(to right, $booked-at-school 50%, $selected 50%);
          }
        }
      }
    }
  }
}
