@font-face {
     font-family: 'Ginger'; /* Ginger Bold */
     src: url('fonts/F37Ginger-Bold.eot');
     src: url('fonts/F37Ginger-Bold.eot?#iefix') format('embedded-opentype'),
          url('fonts/F37Ginger-Bold.woff') format('woff'),
          url('fonts/F37Ginger-Bold.woff2') format('woff2'),
          url('fonts/F37Ginger-Bold.ttf')  format('truetype'),
          url('fonts/F37Ginger-Bold.svg#7db0a735880422cc5fa2f936a390d651') format('svg');         
     font-style:   normal;
     font-weight:  700;
}

@font-face {
     font-family: 'Ginger'; /* Ginger Regular */
     src: url('fonts/F37Ginger-Regular.eot');
     src: url('fonts/F37Ginger-Regular.eot?#iefix') format('embedded-opentype'),
          url('fonts/F37Ginger-Regular.woff') format('woff'),
          url('fonts/F37Ginger-Regular.woff2') format('woff2'),
          url('fonts/F37Ginger-Regular.ttf')  format('truetype'),
          url('fonts/F37Ginger-Regular.svg#cd0eb1181545a540be3acd13cd87c831') format('svg');         
     font-style:   normal;
     font-weight:  400;
}

@font-face {
     font-family: 'Ginger Thin'; /* Ginger Thin */
     src: url('fonts/F37Ginger-Thin.eot');
     src: url('fonts/F37Ginger-Thin.eot?#iefix') format('embedded-opentype'),
          url('fonts/F37Ginger-Thin.woff') format('woff'),
          url('fonts/F37Ginger-Thin.woff2') format('woff2'),
          url('fonts/F37Ginger-Thin.ttf')  format('truetype'),
          url('fonts/F37Ginger-Thin.svg#f3e13b7d84e2887d1408955c128d7a0a') format('svg');         
     font-style:   normal;
     font-weight:  200;
}

@font-face {
     font-family: 'Ginger Light'; /* Ginger Light */
     src: url('fonts/F37Ginger-Light.eot');
     src: url('fonts/F37Ginger-Light.eot?#iefix') format('embedded-opentype'),
          url('fonts/F37Ginger-Light.woff') format('woff'),
          url('fonts/F37Ginger-Light.woff2') format('woff2'),
          url('fonts/F37Ginger-Light.ttf')  format('truetype'),
          url('fonts/F37Ginger-Light.svg#4a1efc7fd28af5705abda90f380987ed') format('svg');
     
     font-style:   normal;
     font-weight:  200;
}