// circle image - used in header and chat
.user-profile-img {
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
}

.square {
  position: relative;
  width: 80px;
  margin: 0 auto;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .content {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    user-select: none;
    border-radius: 50%;
    object-fit: cover;
  }
}
