@font-face {
  font-family: 'Material Icons Round';
  src: url('fonts/material-icons-round.woff2');
}

@font-face {
  font-family: 'Material Icons';
  src: url('fonts/material-icons.woff2');
}

.m-icon {
  font-family: 'Material Icons';
  font-weight: inherit;
  font-size: 1.4rem;
  line-height: 1;
  font-style: inherit;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
}

.m-icon.ro {
  font-family: 'Material Icons Round';
}

.m-icon.outline {
  font-family: 'Material Icons Outlined';
}

.m-icon.xs {
  font-size: 0.5rem;
}

.m-icon.s {
  font-size: 0.7rem;
}

.m-icon.e {
  font-size: 1rem;
}

.m-icon.md {
  font-size: 1.7rem;
}

.m-icon.lg {
  font-size: 2.0rem;
}

.m-icon.va-m {
  vertical-align: middle;
}

.m-icon.fw-b {
  font-weight: bold;
}

.m-icon.fw-n {
  font-weight: normal;
}
